<template>
  <t-page>
    <template #header>
      <h3>{{ $t('pages.applicant.index.heading') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Applicant from '~/models/Applicant';
import IndexTable from '~/pages/shared/applicants/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'admin',

  data: () => ({
    query: () => new Applicant().include('tenant', 'user', 'occupation', 'organization'),

    filters: ['search', 'tenant_id', 'pinged', 'tos_accepted', 'user.verified'],

    fields: [
      'user',
      'organization.name',
      'tenant.name',
      'occupation',
      'organizations_count',
      'address.city',
      'user.verified',
      'tos_accepted',
      'available',
      'created_at',
      'actions',
    ],
  }),
});
</script>
